import { Alert, Box, Button, Checkbox, Container, Dialog, DialogActions, Divider, FormControl, FormControlLabel, FormHelperText, Grid, Paper, TextField, Typography } from "@mui/material";
import { Component } from "react";
import axios from "axios"

class FormNewsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            lastname: null,
            email: null,
            phone: null,
            message: null,
            accept_newsletter: false,
            cv: null,
            data: [
                {
                    type: 'textfield', cols1: 12, cols2: 6, multiline: false, rows: 1, label: 'Email *', name: 'email', id: 'form-email', error: '',
                    errorMessage: 'This is not a valid email', regexp: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                },
                {
                    type: 'checkbox', name: 'accept_newsletter', error: '',
                    errorMessage: 'This is required', regexp: /^(true)$/, skip: true
                },
            ],
            disabled: true,
            messages: {
                'success': <Alert severity="success" action={
                    <Button onClick={this.handleDialogClose} color="inherit" size="small">CLOSE</Button>
                }>Request correctly sent! Thank you.</Alert>,
                'failed': <Alert severity="error" action={
                    <Button onClick={this.handleDialogClose} color="inherit" size="small">CLOSE</Button>
                }>Something went wrong. Please retry later.</Alert>,
            },
            msg: null,
            sent: false,
            checkBoxError: null
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        let isValid = this.validate()
        let formData = new FormData()
        if (isValid) {
            this.setState({ disabled: false })
            let data = this.state.data
            let name
            data.forEach((element, i) => {
                name = element.name
                formData[name] = this.state[name]
                formData.append(name, this.state[name]);
            })
            let form = e.target
            this.send(formData, form)
        } else {
            this.setState({ disabled: true })
        }
    }

    send(formData, form) {
        let messages = this.state.messages
        axios.post('https://api-dev.braino.ai/wavenure/newsletter', formData)
            .then(response => {
                this.setState({ msg: messages.success, sent: true })
                form.reset()
            })
            .catch(error => {
                this.setState({ msg: messages.failed, sent: true })
                console.log(error);
            });
    }

    isJson(data) {
        let ret = true
        if (typeof data !== "object") ret = false
        return ret
    }

    validate() {
        this.setState({ checkBoxError: null })
        let data = this.state.data
        let name, value, pattern, result
        let isValid = true
        data.forEach((element, i) => {
            name = element.name
            pattern = element.regexp
            value = this.state[name]
            if (this.isJson(value)) result = true
            else result = pattern.test(value)

            if (value == null || !result) {
                data[i].error = data[i].errorMessage
                if (element.type == 'checkbox') this.setState({ checkBoxError: data[i].errorMessage })
                isValid = false
            } else {
                data[i].error = ""
            }
        })
        this.setState({ data, disabled: !isValid })
        return isValid
    }

    validateCheckbox() {

    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleCheckbox(e) {
        console.log(e.target.checked)
        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    handleUpload(e) {
        this.setState({ cv: e.target.files[0] }, function () {
            this.validate()
        })
    }

    handleDialogClose = () => {
        this.setState({ sent: false })
    }

    render() {
        const { data, sent, msg, checkBoxError } = this.state
        const { subject } = this.props
        return (
            <div>
                <Dialog open={sent} onClose={this.handleDialogClose} title="titolo">{msg}</Dialog>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box display='flex' flexDirection={'column'} sx={{ mb: 1 }}>
                            <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>Newsletter</Typography>
                            <Typography variant="body2" color={'textSecondary'} sx={{}}>Stay in touch!</Typography>
                            <Typography variant="body2" color={'textSecondary'} sx={{}}>Sign up for our newsletter.</Typography>
                        </Box>
                        <Box>
                            {
                                data.map((obj, i) => (
                                    !obj.skip ?
                                        <Box>
                                            <FormControl variant="standard" fullWidth sx={{ pb: 1 }}>
                                                <Typography color={'textSecondary'} variant="caption">{obj.label}</Typography>
                                                <TextField multiline={obj.multiline} rows={obj.rows} name={obj.name} id={obj.id} placeholder="" variant="outlined" size="small"
                                                    onChange={(e) => this.handleChange(e)}
                                                    onBlur={(e) => this.validate(e)} />
                                                <FormHelperText sx={{ color: 'red' }}>{obj.error}</FormHelperText>
                                            </FormControl>
                                        </Box>
                                        : ''
                                ))
                            }
                            <Box item xs={12}>
                                <FormControl variant="standard" sx={{ mb: 1 }}>
                                    <FormControlLabel control={<Checkbox onChange={(e) => this.handleCheckbox(e)} onBlur={(e) => this.validate(e)} name='accept_newsletter' />}
                                        label={<Typography variant="body2" color={'textSecondary'}>I agree to the privacy policy</Typography>} />
                                    <FormHelperText sx={{ color: 'red' }}>{checkBoxError}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box item xs={12}>
                                <FormControl variant="standard" sx={{ py: 2 }}>
                                    <Button type='submit' variant="outlined">Subscribe</Button>
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                </form>
            </div>
        )
    }
}

export default FormNewsletter
