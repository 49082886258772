import { ArrowRightAlt, ArrowRightOutlined } from "@mui/icons-material";
import { Box, Button, Container, Divider, Fade, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import axios from "axios";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import FooterSection from "../sections/FooterSection";

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }

    componentDidMount() {
        console.log(window.location.href)

        axios.get('https://insights.braino.ai/wp-json/wp/v2/posts?_embed&page=1&per_page=20&lang=en')
            .then(response => {
                const data = response.data
                this.setState({ data })
            })
            .catch(error => {
                console.log(error);
            });

    }

    render() {
        const { data } = this.state
        return (
            <Fade in="true" timeout={650} >
            <div>
                <Container maxWidth='lg'>
                    <Grid item xs={12} sx={{ mt: 2, mb: 6, textAlign: 'center' }}><Typography variant="h3"><b>Braino blog</b></Typography></Grid>
                    <Grid container item spacing={2} sx={{ px: 4 }}>
                        {data ? data.map((obj, i) => (
                            obj.polylang_current_lang.startsWith("en") &&
                            <>
                                {/* {i > 0 ? <Divider sx={{ my: 7 }} /> : ""} */}
                                <Grid item xs={12} md={3} sx={{ textAlign: { xs: 'left', md: 'center' } }}>
                                    <img style={{ width: 150 }} src={obj._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url} />
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="h6"><b dangerouslySetInnerHTML={{ __html: obj.title.rendered.length > 120 ? obj.title.rendered.substring(0, 120) + '...' : obj.title.rendered }} /></Typography>
                                    <Typography variant="caption" gutterBottom color={'textSecondary'}>{obj.date}</Typography>
                                    <Typography variant="body1"><div dangerouslySetInnerHTML={{ __html: obj.excerpt.rendered }} /></Typography>
                                    <Box sx={{ width: '100%', textAlign: 'right', mt: 3 }}>
                                        <Button variant="outlined" size="small" component={NavLink} to={'/post/' + obj.id} endIcon={<ArrowRightAlt />}>Read more</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ my: 7 }} />
                                </Grid>
                            </>
                        )) : "loading..."
                        }
                    </Grid>
                </Container>
                <FooterSection />
                </div>
            </Fade >
        )
    }
}

export default Blog

