import { Box, Button, Container, Fade, Grid, Typography } from "@mui/material";
import { Component, useEffect } from "react";
import CookieBot from "react-cookiebot";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import ReactDOM from 'react-dom';
import FooterSection from "../sections/FooterSection";

class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            declarations: null
        }
    }

    componentDidMount() {
        // axios.get('https://consent.cookiebot.com/d2b799d3-6d5c-4b24-9c03-09a081646448/cd.js')
        //     .then(response => {
        //         console.log(response)
        //         this.setState({ declarations: response })
        //     })
        //     .catch(error => {

        //     });

        // eval(document.getElementById('CookieDeclaration').innerHTML);
        // ReactDOM.render(<script id="CookieDeclaration" data-culture="en" src="https://consent.cookiebot.com/d2b799d3-6d5c-4b24-9c03-09a081646448/cd.js" type="text/javascript" />, document.getElementById('consent'));
        // let url = 'https://consent.cookiebot.com/d2b799d3-6d5c-4b24-9c03-09a081646448/cd.js'
        // this.props.effect(() => {

        //     const script = document.createElement("script");
        //     script.id = "CookieDeclaration";
        //     script.src = url;
        //     script.async = true;
        //     document.body.appendChild(script);

        //     return () => {
        //         document.body.removeChild(script);
        //     }
        // }, [url]);
        ReactDOM.render(<script id="CookieDeclaration" data-culture="en" src="https://consent.cookiebot.com/d2b799d3-6d5c-4b24-9c03-09a081646448/cd.js" type="text/javascript" />, document.getElementById('declarations'));
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props !== prevProps) {
    //         console.log('injecting...')
    //         // this.props.script('https://consent.cookiebot.com/d2b799d3-6d5c-4b24-9c03-09a081646448/cd.js');
    //         ReactDOM.render(<script id="CookieDeclaration" data-culture="en" src="https://consent.cookiebot.com/d2b799d3-6d5c-4b24-9c03-09a081646448/cd.js" type="text/javascript" />, document.getElementById('declarations'));
    //     }
    // }

    render() {
        const { declarations } = this.state
        return (
            <Fade in="true" timeout={650} >
                <div>
                    <CookieBot language="en" domainGroupId='d2b799d3-6d5c-4b24-9c03-09a081646448' />
                    <Container maxWidth='lg' sx={{mb: 7}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} display='flex' flexDirection={'column'} sx={{ mt: 14, mb: 5, textAlign: 'center' }}>
                                <Typography variant="h4"><b>PRIVACY POLICY</b></Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Collection and use of personal data</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    WAVENURE can ask you to provide some personal information like name, surname, email address and, for some specific requests, Linkedin profile URL or phone number via web form. Your information is only used internally and won’t be shared with others.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Your data</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    WAVENURE can ask you to provide some personal information like name, surname, email address and, for some specific requests, Linkedin profile URL or phone number via web form. Your information is only used internally and won’t be shared with others.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Cookies</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    This website uses cookies. We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. You consent to our cookies if you continue to use our website.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Cookies are small text files that can be used by websites to make a user's experience more efficient.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.
                                </Typography>
                                {/* <Typography variant="body1" sx={{ mt: 2 }}>
                                    You can at any time change or withdraw your consent from the Cookie Declaration on our website.
                                </Typography> */}
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.
                                </Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Your consent applies to the following domains: wavenure.ai, wavenure.com, braino.ai
                                </Typography>
                                <div id="declarations"></div>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Ad Server</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    We do not partner with or have special relationships with any ad server companies.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Security</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    All data and information transmitted with Service is secured by SSL protocol.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Modifications</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    If our information practices change at some time in the future we will post the policy changes to our Web site to notify you of these changes and we will use for these new purposes only data collected from the time of the policy change forward. If you are concerned about how your information is used, you should check back at our Web site periodically.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                    <FooterSection />
                </div>
            </Fade>
        )
    }
}

export default Privacy

