import { Box, Button, Checkbox, Container, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Component } from "react";

class Partners extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { bgcolor: '#f6f6f6', img: "/img/feat_tgcom24_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_xeurope_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_sas_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_pwc_logo.svg" },
                { bgcolor: '#f6f6f6', img: "/img/feat_parisfintechforum_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_milanfintechsummit_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_levillage_logo.svg" },
                { bgcolor: '#f6f6f6', img: "/img/feat_ilsole24ore_logo.svg" },
                { bgcolor: '#f6f6f6', img: "/img/feat_forumbanca_logo.svg" },
                { bgcolor: '#f6f6f6', img: "/img/feat_Fintech_District_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_fintastico_logo.svg" },
                { bgcolor: '#f6f6f6', img: "/img/feat_businessinsider_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_aziendabanca_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_AIFinTech100.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_AI Summit London Logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_affaritaliani_logo.jpg" },
                { bgcolor: '#f6f6f6', img: "/img/feat_affariefinanza_logo.png" },
                { bgcolor: '#f6f6f6', img: "/img/feat_bebeez_logo.png" },
            ]
        }
    }

    render() {
        const { data } = this.state
        return (
            <Container maxWidth={false} sx={{ p:0, minHeight: 'calc(100vh - 80px)', backgroundColor: '#fcfcff', display: 'flex', alignItems: 'center' }} id='partners'>
                <Grid container sx={{ py: 8, px: { xs: 4, lg: 32 } }} spacing={3}>
                    <Grid item xs={12} sx={{ mb: 6, textAlign: 'center' }}><Typography variant="h3"><b>Featured in</b></Typography></Grid>
                    {data.map(obj => (
                        <Grid item xs={12} sm={6} md={3} display='flex' flexDirection={'column'} sx={{ textAlign: 'center' }}>
                            <Paper elevation={0} sx={{ backgroundColor: obj.bgcolor, p: 2 }}>
                                <img src={obj.img} style={{ height: 50, maxWidth: 130, objectFit: 'contain', opacity: .8 }} />
                            </Paper>
                        </Grid>
                    ))
                    }
                </Grid>
            </Container>
        )
    }
}

export default Partners

