import { Container, Grid } from "@mui/material";
import { Component } from "react";
import Posts from "../components/Posts";
import { green } from "@mui/material/colors";

class Blog extends Component {
    render() {
        return (
            <Container maxWidth={false} sx={{ minHeight: 'calc(100vh)', backgroundColor: '#fff', display: 'flex', alignItems: 'center' }} id='blog'>
                <Grid container sx={{ p: 4 }}>
                    <Posts />
                </Grid>
            </Container>
        );
    }
}

export default Blog;