import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Container, Divider, Grid, Link, List, ListItem, ListItemAvatar, Typography } from "@mui/material";
import { Component } from "react";
import { Done } from "@mui/icons-material";

class Plans extends Component {
    render() {
        return (
            <Container maxWidth={'xl'} sx={{ mb: 2, minHeight: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center' }} id='plans'>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mb: 6, textAlign: 'center' }}><Typography variant="h3"><b>Our plans</b></Typography></Grid>
                    <Grid item xs={12} sm={6} xl={6} sx={{ mb: 2 }}>
                        <Box sx={{ px: { xs: 4, lg: 2, xl: 2 }, height: '100%' }}>
                            <Card variant="elevation" elevation={24} sx={{ height: '100%' }}>
                                <CardActionArea component={Link} href='//app.braino.ai/register.html' target={'APP'} sx={{ height: '100%' }}>
                                    <CardMedia sx={{ backgroundImage: "url(img/magicpattern-2.png)", backgroundSize: 'cover', p: 2, color: 'white' }}>
                                        <Typography>Braino FREE</Typography>
                                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                            <Typography variant="body2">€</Typography>
                                            <Typography variant="h3">0</Typography>
                                            <Typography variant="body2">/month</Typography>
                                        </Box>
                                    </CardMedia>
                                    <CardContent>
                                        <List>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Even more positions to open and favorites to follow</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Portfolios: access to portfolios components</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Edit your preferences</ListItem>
                                        </List>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' }, my: 4 }}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={6} xl={6} sx={{ mb: 2 }}>
                        <Box sx={{ px: { xs: 4, lg: 2, xl: 2 }, height: '100%' }}>
                            <Card variant="elevation" elevation={24} sx={{ height: '100%' }}>
                                <CardActionArea component={Link} href='//app.braino.ai/register.html' target={'APP'} sx={{ height: '100%' }}>
                                    <CardMedia sx={{ backgroundImage: "url(img/magicpattern-1.png)", backgroundSize: 'cover', p: 2, color: 'white' }}>
                                        <Typography>Braino PLUS</Typography>
                                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                            <Typography variant="body2">€</Typography>
                                            <Typography variant="h3">9.99</Typography>
                                            <Typography variant="body2">/month</Typography>
                                        </Box>
                                    </CardMedia>
                                    <CardContent>
                                        <List>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>25 custom advices per day</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>12 alternative advices per day</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>40 favorite contemporary instruments</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>50 contemporary open positions</ListItem>
                                        </List>
                                        {/* <Button variant='contained' size='large' disableElevation sx={{ mt: 2 }} component={Link} href='https://apps.braino.ai/register.html' target={'APP'}>Register now</Button> */}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' }, my: 4 }}>
                        <Divider />
                    </Grid>

                    {/* <Grid item xs={12} sm={6} xl={3} sx={{ mb: 2 }}>
                        <Box sx={{ px: { xs: 4, lg: 2, xl: 2 }, height: '100%' }}>
                            <Card variant="elevation" elevation={24} sx={{ height: '100%' }}>
                                <CardActionArea component={Link} href='//app.braino.ai/register.html' target={'APP'} sx={{ height: '100%' }}>
                                    <CardMedia sx={{ backgroundImage: "url(img/magicpattern-1.png)", backgroundSize: 'cover', p: 2, color: 'white' }}>
                                        <Typography>Braino PREMIUM</Typography>
                                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                            <Typography variant="body2">€</Typography>
                                            <Typography variant="h3">79.00</Typography>
                                            <Typography variant="body2">/month</Typography>
                                        </Box>
                                    </CardMedia>
                                    <CardContent>
                                        <List>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Perform your trading orders directly in app</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>40 custom advices per day</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>40 alternative advices per day</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>40 favorite contemporary instruments</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>50 contemporary open positions</ListItem>
                                        </List>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' }, my: 4 }}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} sm={6} xl={3} sx={{ mb: 2 }}>
                        <Box sx={{ px: { xs: 4, lg: 2, xl: 2 }, height: '100%' }}>
                            <Card variant="elevation" elevation={24} sx={{ height: '100%' }}>
                                <CardActionArea component={Link} href='//app.braino.ai/register.html' target={'APP'} sx={{ height: '100%' }}>
                                    <CardMedia sx={{ backgroundImage: "url(img/magicpattern-builder.png)", backgroundSize: 'cover', p: 2, color: 'white' }}>
                                        <Typography>Portfolio Builder</Typography>
                                        <Box sx={{ p: 1, display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                            <Typography variant="body2">€</Typography>
                                            <Typography variant="h3">299.00</Typography>
                                            <Typography variant="body2">/month</Typography>
                                        </Box>
                                    </CardMedia>
                                    <CardContent>
                                        <List>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Set initial capital, size, currency and operativity</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Build your custom portfolio and compare different configurations on the fly</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Get daily advices to optimize your investement</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Stay up-to-date on your performances with portfolio reports</ListItem>
                                            <ListItem sx={{ px: 0 }}><ListItemAvatar sx={{ minWidth: 40 }}><Done color='primary' /></ListItemAvatar>Keep everything under control with compare views and detailed breakdowns</ListItem>
                                        </List>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Box>
                    </Grid> */}

                </Grid>
            </Container>
        );
    }
}

export default Plans;