import { AppBar, Box, Button, createTheme, CssBaseline, Divider, Drawer, IconButton, Link, List, ListItem, ListItemButton, ListItemText, ThemeProvider, Toolbar, Typography } from '@mui/material';
import { Component } from 'react';
import './App.css';
import ThemeLight from './themes/ThemeLight';
import { Route, Routes, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import Home from './pages/Home';
import { Menu } from '@mui/icons-material';
import Footer from './components/Footer';
import ReactGa from 'react-ga';
import Post from './pages/Post';
import Blog from './pages/Blog';
import Contacts from './pages/Contacts';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light' ? ThemeLight.palette : ThemeLight.palette),
  },
});
let theme = createTheme(getDesignTokens('light'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'light',
      mobileOpen: false,
      navItems: [
        { label: 'Home', link: 'home' },
        { label: 'How it works', link: 'howitworks' },
        { label: 'Skills', link: 'skills' },
        { label: 'Performance', link: 'performance' },
        { label: 'Plans', link: 'plans' },
        // { label: 'Braino Pro', link: 'braino' },
        { label: 'Featured in', link: 'partners' },
        // { label: 'Blog', link: 'blog' },
      ],
      anchor: null,
    }
  }

  componentDidMount() {
    this.setState({ mode: 'light' })
    theme = createTheme(getDesignTokens('light'));
    theme.components = ThemeLight.components

    window.addEventListener('resize', this.handleWindowResize);

    ReactGa.initialize('GTM-N44DRLN')
  }

  handleDrawerToggle = () => {
    this.setState(state => ({
      mobileOpen: !state.mobileOpen
    }));
  }

  handleWindowResize = () => {
    this.setState({ mobileOpen: false });
  }

  handleMenu = (e, id) => {
    this.setState({ anchor: id }, () => {
      e.preventDefault()
      e.stopPropagation()
      this.state.anchor = id

      let pageUrl = window.location.hash.replace("#/", "")
      if (pageUrl != "") {
        this.props.navigate('/')
        setTimeout(() => {
          this.scrollTo(id)
        }, 200);
      } else {
        this.scrollTo(id)
      }
    });
  }

  scrollTo(id) {
    window.scrollTo({
      behavior: 'smooth',
      top:
        document.getElementById(id).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top - 80,
    })
  }

  render() {
    const { classes } = this.props;
    const { navItems, mobileOpen, anchor } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar elevation={0} sx={{ p: 1 }}>
          <Toolbar sx={{ mx: { xs: 1, lg: 6 } }}>
            <IconButton sx={{ display: { xs: 'inherit', lg: 'none' }, mr: 2 }} onClick={this.handleDrawerToggle} >
              <Menu />
            </IconButton>
            <Box component={Link} href={"/"} onClick={(e) => this.handleMenu(e, 'home')} sx={{ flexGrow: 1, display: { xs: 'none', lg: 'block' } }}>
              <img src="/img/logo.svg" style={{ maxWidth: 150 }} alt="logo" />
            </Box>
            <Box component={NavLink} to='/' sx={{ flexGrow: 1, display: { xs: 'block', lg: 'none' } }}>
              <img component={Link} href={"/"} onClick={(e) => this.handleMenu(e, 'home')} src="/img/logo_brainonly.svg" style={{ maxWidth: 55 }} alt="logo" />
            </Box>
            <Box sx={{ display: { xs: 'none', lg: 'flex' }, alignItems: 'center' }}>
              <Link sx={{ mr: 1, px: 2 }} href={"/"} onClick={(e) => this.handleMenu(e, 'home')} className='links'>Home</Link>
              <Link sx={{ mr: 1, px: 2 }} href={"/"} onClick={(e) => this.handleMenu(e, 'howitworks')} className='links'>How it works</Link>
              <Link sx={{ mr: 1, px: 2 }} href={"/"} onClick={(e) => this.handleMenu(e, 'skills')} className='links'>Skills</Link>
              <Link sx={{ mr: 1, px: 2 }} href={"/"} onClick={(e) => this.handleMenu(e, 'performance')} className='links'>Performance</Link>
              <Link sx={{ mr: 1, px: 2 }} href={"/"} onClick={(e) => this.handleMenu(e, 'plans')} className='links'>Plans</Link>
              {/* <Link sx={{ mr: 1, px: 2 }} href={"/"} onClick={(e) => this.handleMenu(e, 'braino')}>Braino Pro</Link> */}
              <Link sx={{ mr: 1, px: 2 }} href={"/"} onClick={(e) => this.handleMenu(e, 'partners')} className='links'>Featured in</Link>
              {/* <Link sx={{ mr: 1, px: 2 }} href={"/"} onClick={(e) => this.handleMenu(e, 'blog')}>Blog</Link> */}
              <Link sx={{ mr: 1, px: 2 }} href={"//next.braino.ai"} target={'NEXT'} onClick={(e) => { }} className='links'>
                <Typography color={"primary"}><b>What's next?</b></Typography>
              </Link>
              <Button variant='contained' size='large' disableElevation sx={{ mx: 2 }} component={Link} href='//app.braino.ai' target={'APP'}>Login</Button>
            </Box>
            <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
              <Button variant='contained' size='large' disableElevation sx={{ mx: 2 }} component={Link} href='//app.braino.ai' target={'APP'}>Login</Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', lg: 'none' },
              //'& .MuiDrawer-paper': { boxSizing: 'border-box', width: 250 },
            }}
          >
            <Box onClick={this.handleDrawerToggle} sx={{ textAlign: 'center' }}>
              <Box sx={{ p: 4 }}>
                <img src="/img/logo.svg" alt="logo" style={{ maxWidth: '100%' }} />
              </Box>
              <Divider />
              <List>
                {navItems.map((item, i) => (
                  <ListItem key={i} disablePadding onClick={(e) => this.handleMenu(e, item.link)}>
                    <ListItemButton>
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
                <ListItem>
                  <Link href={"//next.braino.ai"} target={'NEXT'} onClick={(e) => { }} className='links'>
                    <Typography color={"primary"}><b>What's next?</b></Typography>
                  </Link>
                </ListItem>
                <ListItem>
                  <Button variant='contained' size='large' disableElevation sx={{}} component={Link} href='//app.braino.ai' target={'APP'}>Login</Button>
                </ListItem>
              </List>
            </Box>
          </Drawer>
        </Box>
        <Toolbar sx={{ height: 80 }} />
        <Box sx={{ flexGrow: 1, p: 0 }}>
          <Routes>
            <Route exact path="/" element={<Home anchor={anchor} />} />
            <Route path="/post/:id" element={<Post />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/contacts" element={<Contacts />} />
            <Route exact path="/privacy" element={<Privacy />} />
            <Route exact path="/terms" element={<Terms />} />
            <Route path="/*" element={
              <>
                <Box sx={{ p: 20 }}>
                  <Typography variant='h2'>404: Page not found</Typography>
                </Box>
                <Footer />
              </>
            } />
          </Routes>
        </Box>
      </ThemeProvider>
    );
  }
}

export function AppWithRouter(props) {
  const navigate = useNavigate()

  return <App navigate={navigate} {...props} />
}

export default App;