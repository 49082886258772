import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import { Component } from "react";

class Section2 extends Component {
    scrollTo(id) {
        this.props.scrollTo(id)
    }

    render() {
        return (
            <Container maxWidth={false} id="other2" sx={{ p: '0!important', minHeight: 'calc(100vh - 80px)', display: 'flex' }}>
                <Grid container sx={{ minHeight: '100%' }}>
                    <Grid item xs={12} md={6} sx={{ backgroundImage: 'url(img/invest.png)', backgroundSize: 'cover', minHeight: 200 }}>

                    </Grid>
                    <Grid item xs={12} md={6} order={{}}>
                        <Box sx={{ height: '100%', py: { xs: 8, md: 1 }, px: { xs: 4, lg: 16, xl: 24 }, display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
                            <Typography variant="h1" color={'primary'}><b>Invest</b></Typography>
                            <Typography variant="h4" sx={{ fontWeight: 400 }}>Braino manages your investments and alerts you when it is time to trade.</Typography>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <IconButton sx={{ mt: 16, }} onClick={() => this.scrollTo('other3')}>
                                    <KeyboardArrowDown color="primary" sx={{ fontSize: 80 }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default Section2;