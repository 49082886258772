import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import { BatteryChargingFullTwoTone, EscalatorTwoTone, FaceTwoTone, MemoryTwoTone, ThumbUpTwoTone, ViewModuleTwoTone } from "@mui/icons-material";
import { deepPurple } from "@mui/material/colors";

class Skills3 extends Component {
    render() {
        return (
            <Container maxWidth={false} sx={{ minHeight: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', backgroundColor: deepPurple[100] }} id="skills3">
                <Grid container item md={12} order={{}} spacing={2}>
                    <Grid item lg={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'center', py: { xs: 8, md: 1 }, px: { xs: 4, xl: 24 } }}>
                            {/* <BatteryChargingFullTwoTone color="primary" sx={{ fontSize: 80 }} /> */}
                            <img src="img/skills-complete.png" />
                            <Typography variant="h2" sx={{ my: 2, display: {xs: 'none', md: 'block'} }}><b>Complete</b></Typography>
                            <Typography variant="h3" sx={{ my: 2, display: {xs: 'block', md: 'none'} }}><b>Complete</b></Typography>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" sx={{ fontWeight: 300 }}>Braino generate a blend of multi-
                                    horizon strategies and diversified
                                    portfolios and offer a smarter way
                                    to invest.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'center', py: { xs: 8, md: 1 }, px: { xs: 4, xl: 24 } }}>
                            {/* <EscalatorTwoTone color="primary" sx={{ fontSize: 80 }} /> */}
                            <img src="img/skills-progressive.png" />
                            <Typography variant="h2" sx={{ my: 2, display: {xs: 'none', md: 'block'} }}><b>Progressive</b></Typography>
                            <Typography variant="h3" sx={{ my: 2, display: {xs: 'block', md: 'none'} }}><b>Progressive</b></Typography>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" sx={{ fontWeight: 300 }}>The more Braino knows you, the
                                    better it acts for you.
                                    And if you prefer to deal only with
                                    the results, Brano can
                                    automatically invest your capital
                                    and manage your portfolio.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default Skills3;