import { AlternateEmail, Devices, Email, Mouse, Public } from "@mui/icons-material";
import { Box, Button, Checkbox, Container, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import FormNewsletter from "./FormNewsletter";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Grid container sx={{ px: { xs: 8, md: 8 }, py: 8 }}>
                <Grid item xs={12} sm={6} lg={4} display='flex' flexDirection={'column'} sx={{ mt: 5, textAlign: 'left' }}>

                    <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>Braino</Typography>
                    <Typography variant="body1" color={'textSecondary'}>Braino.AI © {new Date().getFullYear()}</Typography>
                    <Typography variant="body2" color={'textSecondary'} gutterBottom>Braino.AI S.R.L.S.B. (SOCIETÀ BENEFIT)</Typography>
                    <Typography variant="body2" color={'textSecondary'}>Registered office: via Ippodromo 56,</Typography>
                    <Typography variant="body2" color={'textSecondary'} gutterBottom>20151, Milan, Italy</Typography>
                    <Typography variant="body2" color={'textSecondary'}>Headquarter: via Marcantonio Colonna 35,</Typography>
                    <Typography variant="body2" color={'textSecondary'} gutterBottom>20149, Milan, Italy</Typography>
                    <Typography variant="body2" color={'textSecondary'}>VAT ID:13049530960</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}> <Devices color={'textSecondary'} sx={{mr: 1}} /> <Typography variant="body2" color={'textSecondary'}>braino.ai</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 0 }}> <AlternateEmail color={'textSecondary'} sx={{mr: 1}} /> <Typography variant="body2" color={'textSecondary'}>hello@braino.ai</Typography></Box>

                    
                    <Typography sx={{ mt: 4 }} variant="body2" color={'textSecondary'}>Powered by</Typography>
                    <Typography variant="body1" color={'textSecondary'}>WAVENURE © {new Date().getFullYear()}</Typography>
                    <Typography variant="body2" color={'textSecondary'}>All rights reserved</Typography>
                    <Typography variant="body2" color={'textSecondary'}>Milan, Italy</Typography>
                    <Typography variant="body2" color={'textSecondary'}>London, UK</Typography>
                    <Typography variant="body2" color={'textSecondary'}>VAT ID: 09355870966</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}> <Public color={'textSecondary'} sx={{mr: 1}} /> <Typography variant="body2" color={'textSecondary'}>wavenure.com</Typography></Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}> <AlternateEmail color={'textSecondary'} sx={{mr: 1}} /> <Typography variant="body2" color={'textSecondary'}>hello@wavenure.com</Typography></Box>

                    <img src="/img/logo.svg" style={{ maxWidth: 150, filter: 'grayscale(1) brightness(1.8)', marginTop: 16 }} alt="logo" />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Box sx={{ mt: 5, display: 'inline-flex', flexDirection: 'column', textAlign: 'left' }}>
                        <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>Legal area</Typography>
                        <Typography variant="body2" color={'textSecondary'} className='linksOnDark' component={NavLink} to='/privacy'>Privacy Policy</Typography>
                        <Typography variant="body2" color={'textSecondary'} className='linksOnDark' component={NavLink} to='/terms'>Terms and conditions</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={4}>
                    <Box sx={{ mt: 5, display: 'inline-flex', flexDirection: 'column', textAlign: 'left' }}>
                        <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>Disclaimer</Typography>
                        <Typography variant="body2" color={'textSecondary'} className='linksOnDark' component={NavLink} to='/privacy'>All investments are subject to risks. Braino.AI and WAVENURE are not responsible for any loss or damage as a consequence of the information reported in this site and the related ones, including data, charts, quotes, signals and technical comments.</Typography>
                    </Box>
                    <Box sx={{ mt: 5 }}>
                        <Typography variant="h6" sx={{ color: 'white', mb: 1 }}>Follow us</Typography>
                        <Link href='https://www.linkedin.com/company/wavenure/' target={'SOCIAL'}><img src="img/linkedin_white.svg" style={{ maxHeight: 40, marginRight: 8 }} /></Link>
                        <Link href='https://www.facebook.com/Wavenure' target={'SOCIAL'}><img src="img/facebook_white.svg" style={{ maxHeight: 40, marginRight: 8 }} /></Link>
                        <Link href='https://twitter.com/Wavenure' target={'SOCIAL'}><img src="img/twitter_white.svg" style={{ maxHeight: 40, marginRight: 8 }} /></Link>
                        <Link href='https://www.youtube.com/channel/UCEd5egApHI25FwZX479e_SQ' target={'SOCIAL'}><img src="img/youtube_white.svg" style={{ maxHeight: 40, marginRight: 8 }} /></Link>
                        <Link href='https://instagram.com/wavenure?igshid=3oe155nzq0mn' target={'SOCIAL'}><img src="img/Instagram_white.svg" style={{ maxHeight: 40, marginRight: 8 }} /></Link>
                    </Box>
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} sx={{ mt: 5, textAlign: 'center' }}>
                    <Divider />
                </Grid>
                <Grid item xs={12} display='flex' flexDirection={'column'} sx={{ mt: 5, textAlign: 'center' }}>
                    <Typography variant="body2" sx={{ color: 'white' }}>Copyright © {new Date().getFullYear()} Wavenure. All Rights Reserved.</Typography>
                </Grid>
            </Grid>
        )
    }
}

export default Footer

