import { Alert, Box, Button, Container, Dialog, Fade, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, TextareaAutosize, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Component } from "react";
import Footer from "../components/Footer";
import FooterSection from "../sections/FooterSection";

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                {
                    cols1: 12, cols2: 12, multiline: false, rows: 1, label: 'Name *', name: 'name', id: 'form-name', error: '',
                    errorMessage: 'Something wrong in your name', regexp: /^[A-Za-zÀ-ÿ'’-][A-Za-zÀ-ÿ'’-]{1,30}( [A-Za-zÀ-ÿ'’-][A-Za-zÀ-ÿ'’-]{1,30})*$/
                },
                {
                    cols1: 12, cols2: 12, multiline: false, rows: 1, label: 'Email *', name: 'email', id: 'form-email', error: '',
                    errorMessage: 'This is not a valid email', regexp: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                },
                {
                    cols1: 12, cols2: 12, multiline: false, rows: 1, label: 'Subject *', name: 'subject', id: 'form-subject', error: '',
                    errorMessage: 'Something wrong in your subject', regexp: /^.{3,30}$/
                },
                {
                    cols1: 12, cols2: 12, multiline: true, rows: 10, label: 'Message *', name: 'message', id: 'form-message', error: '',
                    errorMessage: 'Min 30 chars, max 100', regexp: /^.{30,2000}$/
                },
            ],
            disabled: true,
            messages: {
                'success': <Alert severity="success" action={
                    <Button onClick={this.handleDialogClose} color="inherit" size="small">CLOSE</Button>
                }>Request correctly sent! Thank you.</Alert>,
                'failed': <Alert severity="error" action={
                    <Button onClick={this.handleDialogClose} color="inherit" size="small">CLOSE</Button>
                }>Something went wrong. Please retry later.</Alert>,
            },
            msg: null,
            sent: false,
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        let isValid = this.validate()
        let formData = new FormData()
        if (isValid) {
            this.setState({ disabled: false })
            let data = this.state.data
            let name
            data.forEach((element, i) => {
                name = element.name
                formData[name] = this.state[name]
                formData.append(name, this.state[name]);
            })
            this.send(formData)
            e.target.reset();
        } else {
            this.setState({ disabled: true })
        }
    }

    send(formData) {
        let messages = this.state.messages
        axios.post('https://api-dev.braino.ai/www/contacts', formData)
            .then(response => {
                this.setState({ msg: messages.success, sent: true })
            })
            .catch(error => {
                this.setState({ msg: messages.failed, sent: true })
                console.log(error);
            });
    }

    isJson(data) {
        let ret = true
        if (typeof data !== "object") ret = false
        return ret
    }

    validate() {
        let data = this.state.data
        let name, value, pattern, result
        let isValid = true
        data.forEach((element, i) => {
            name = element.name
            pattern = element.regexp
            value = this.state[name]
            if (this.isJson(value)) result = true
            else result = pattern.test(value)

            console.log('name', name, 'value', value, 'response', result)

            if (value == null || !result) {
                data[i].error = data[i].errorMessage
                isValid = false
            } else {
                console.log(name, result, 'bravoo!!')
                data[i].error = ""
            }
        });
        this.setState({ data, disabled: !isValid })
        return isValid
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleCheckbox(e) {
        console.log(e.target.checked)
        this.setState({
            [e.target.name]: e.target.checked
        })
    }

    handleUpload(e) {
        this.setState({ cv: e.target.files[0] }, function () {
            this.validate()
        })
    }

    resetForm = (e) => {
        let data = this.state.data
        let name
        data.forEach((element, i) => {
            name = element.name
            this.setState[name] = null
        });

    }

    handleDialogClose = () => {
        this.setState({ sent: false })
    }

    render() {
        const { data, disabled, sent, msg } = this.state
        return (
            <Fade in="true" timeout={650} >
                <div>
                    <Dialog open={sent} onClose={this.handleDialogClose} title="titolo">{msg}</Dialog>
                    <Container maxWidth='sm' sx={{mb: 7}}>
                        <Grid container>
                            <Grid item xs={12} display='flex' flexDirection={'column'} sx={{ mt: 14, mb: 5, textAlign: 'center' }}>
                                <Typography variant="h4" color={''}><b>Contact us</b></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <form onSubmit={(e) => this.handleSubmit(e)}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        {
                                            data.map((obj, i) => (
                                                !obj.skip ?
                                                    <FormControl variant="standard" fullWidth sx={{ py: 2 }}>
                                                        <Typography variant="body1">{obj.label}</Typography>
                                                        <TextField multiline={obj.multiline} rows={obj.rows} name={obj.name} id={obj.id} placeholder="" variant="outlined" size="medium"
                                                            onChange={(e) => this.handleChange(e)}
                                                            onBlur={(e) => this.validate(e)} />
                                                        <FormHelperText sx={{ color: 'red' }}>{obj.error}</FormHelperText>
                                                    </FormControl>
                                                    : ''
                                            ))
                                        }
                                        <Box sx={{mt: 3}}>
                                        {/* <Button type="reset" sx={{mr:2}}>Reset</Button> */}
                                        <Button size="large" disableElevation type='submit' variant="contained" sx={{maxWidth: 200 }}>Submit</Button>
                                        </Box>
                                    </Box>
                                </form>
                            </Grid>
                        </Grid>
                    </Container>
                    <FooterSection />
                </div>
            </Fade>
        )
    }
}

export default Contacts

