import { Box, Button, Container, Fade, Grid, Typography } from "@mui/material";
import { Component, useEffect } from "react";
import CookieBot from "react-cookiebot";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import ReactDOM from 'react-dom';
import FooterSection from "../sections/FooterSection";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            declarations: null
        }
    }

    componentDidMount() {

    }

    render() {
        const { declarations } = this.state
        return (
            <Fade in="true" timeout={650} >
                <div>
                    <Container maxWidth='lg' sx={{ mb: 7 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} display='flex' flexDirection={'column'} sx={{ mt: 14, mb: 5, textAlign: 'center' }}>
                                <Typography variant="h4"><b>Terms & Conditions</b></Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Thanks for choosing Braino. Braino service ("Service") is provided by WAVENURE Ltd. ("WAVENURE"). By using our Service, you are agreeing to these Terms of Service ("Terms") and certify that you are over the age of 18. If you are using the Service on behalf of an organization or entity ("Organization"), then you are agreeing to these Terms on behalf of the Organization. In that case, "you" and "your" may refer to the Organization. Please read these terms carefully before start using our Service.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>New terms of service</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    From time to time, WAVENURE may modify existing terms and/or update these terms with additional terms that apply to the Service. For example, WAVENURE may modify existing terms to reflect any mandatory changes to the law. You should check the terms regularly to keep informed. WAVENURE will also post notice of modification to these Terms on this page or via the email address you registered with us. Note, changes will not apply retroactively and will become effective thirty (30) days after they are posted on our website. Changes addressing new functions for the Service or changes made for legal reasons may be effective immediately. You should discontinue your use of our Service if you do not agree with updated/modified Terms.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Privacy Policy</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    WAVENURE's privacy policies explain how we treat your personal data and protect your privacy when using our Service. By using our service, you automatically accept our privacy policy and authorize WAVENURE to use such data in accordance with the policy itself. In addition, if you sign up to our services, and where allowed by law, we will assume you want us to contact you by e-mails relating to the service itself and notification. We may use the personal data we have collected about you in order to tailor our messages to you.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Your contents on our service</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Our service allows you to upload, download and store content, including but not limited to, information, text, graphics, videos, audios, or other material ("Content"). You retain ownership of any intellectual property rights that you hold in that Content. When you upload, store, send or receive Content to or through our Service, you give WAVENURE and its service partners a worldwide license to host, store, upload and download this Content and only for the purpose of providing Service strictly to you and your use of the Content. We reserve our right at all times, but are not obligated, to remove or refuse to distribute any Content through the Service including your Content.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Software use on our service</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    When the Service requires or includes downloadable software, this software may update automatically on your device once a new version or features become available to you. Some platforms may let you adjust your automatic update settings. WAVENURE gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided by WAVENURE as part of the Service. You may not copy, modify, distribute, sell, or lease any part of our Service or included software, nor may you reverse engineer or attempt to extract the source code of the software, unless local laws prohibit those restrictions or you have our written permission.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Modifications and interruptions to our service</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    WAVENURE may add or remove functionalities or features in the normal course of improving, changing, and/or updating the Service. We may also suspend or stop our Service with at least thirty (30) days notice prior to the complete shutdown of our Service. You can choose to stop using our Service at will. We may also stop providing Service to you, or add or create new limitations to our Service at any time as detailed in the Terms.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Usage of our service</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    You must follow any policies made available to you within the Service. You may only use our Service as permitted by law. WAVENURE may investigate and/or suspend or terminate our Service to you at any time if we find your use of our Service violates the Terms and/or any policies.
                                    Using our Service does not grant you ownership of any intellectual property rights in our Service or the content you may have access to. You may not use any copyrighted content in our Service unless you obtain permission from the content owner and/or are otherwise permitted by law. The Terms do not grant you the right to use any branding or logos used in our Service. Our Service may display some logos, trademarks, or branding materials that is not the property of WAVENURE. These types of content are the sole responsibility of the entity that makes it available. You must not abuse and/or misuse our Service, including but not limited to, doing the following things:
                                    Using the Service for any unlawful purposes or activities;
                                    Uploading any content to the Service in violation of any applicable law, including but not limited to, intellectual property laws and publicity laws;
                                    Sending unsolicited promotions or advertisements;
                                    Accessing or tampering with the Service's server systems;
                                    Interfering with or disrupting the access of any user, host, or network;
                                    WAVENURE, in its sole discretion, will determine abuse and/or misuse of our Service.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Subscription and payment</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    You may choose our free Service or paid Service ("Premium") depending on your need. We do not guarantee when, if ever, Premium features will be available in the free Service. You can upgrade from free Service to Premium at any time by following instructions on our pricing page("Plans").
                                    When a free trial of Premium is offered, you will have access to all Premium features. After the free trial period, if you choose not to upgrade to Premium, features available to you will be limited to free Service. You will be charged with the amount shown on Pricing before you can access Premium. All prices shown on Pricing are inclusive of any applicable sales taxes, levies, value-added taxes, or duties imposed by taxing authorities, and you are responsible for payment of all such taxes, levies, or duties.

                                    By purchasing premium you expressly understand and agree to our refund policy:
                                    Within thirty (30) days of your premium payment date as shown on your payment bill, you can request a full refund by contacting us. No refund of any kind will be permitted after thirty (30) days of your premium payment date.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Limited liability for our service</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    To the maximum extent permitted by law, the service is available "as is". You expressly understand and agree that:
                                    when permitted by law, WAVENURE and its service partners, licensors, employees, agents will not be responsible for any lost profits, revenues, or data, financial losses or indirect, special, consequential, exemplary, or punitive damages under any circumstances.;
                                    Your use and/or purchase of service are all at your sole risk. The service is provided on an "as is" basis.
                                    WAVENURE does not warrant that:
                                    The service will meet all of your requirements at all times;
                                    The service will be error-free and all errors in the service will be corrected;
                                    The service will be uninterrupted.
                                    All content downloaded, uploaded and/or otherwise obtained through the use of the service is done at your own discretion and risk and you are solely responsible for any damage to your computer equipment or devices, including but not limited to, laptop computers, desktop computer, tablets, smartphones and smartwatches, or any data loss resulting from download or use of any such above mentioned material.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                                <Typography variant="h4"><b>Disclaimer</b></Typography>
                                <Typography variant="body1" sx={{ mt: 2 }}>
                                    Braino.AI makes decisions based on proprietary research and methodologies of Braino itself, and of WAVENURE's COGNITIVE systems. Braino's decisions and all contents are the result of automatic processing and are not necessarily a useful indication of the future perspectives of the variables analysed nor of the financial instruments.
                                    WAVENURE makes every effort to ensure that the information is accurate, and pays the utmost care and attention to the quality of the analysis and contents, and to the profitability of Braino's decisions. However, Braino's decisions and contents are also derived from third party data, for which WAVENURE has no responsibility. Therefore, WAVENURE is not responsible for errors, delays or any form of inaccuracy in the information contained in this service.
                                    It is important to remember that investing in financial markets and instruments involves significant risks, and that past performance is no guarantee of future performance. Any value or potential return shown does not consider subjective variables, such as transaction size, brokerage fees or taxes, which inevitably affect real investment returns. We recommend the utmost caution in all investment decisions, assessing your level of risk, informing yourself independently and accurately, and seeking advice from your professional advisor, before making an investment decision.
                                    No specialised, personalised or individual financial advice is provided in this service. Each user can view a selection, based on his or her preferences and interests, of investment decisions that Braino makes in total autonomy and independence. We do not warrant or guarantee in any way the success of any action that is taken based on Braino's decisions on the basis of Braino's decisions and on the contents of this service. The contents of this service do not constitute a recommendation to buy or sell any of the financial instruments analysed. WAVENURE is not and will not be responsible for the way the service is used. Any investment decision is taken by the user in full autonomy and at his or her own risk.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                    <FooterSection />
                </div>
            </Fade>
        )
    }
}

export default Terms

