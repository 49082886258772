import { Box, Button, Container, Grid, IconButton, Typography } from "@mui/material";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import { BatteryChargingFullTwoTone, EscalatorTwoTone, FaceTwoTone, KeyboardArrowDown, MemoryTwoTone, ThumbUpTwoTone, ViewModuleTwoTone } from "@mui/icons-material";
import { deepPurple } from "@mui/material/colors";

class Skills1 extends Component {
    scrollTo(id) {
        this.props.scrollTo(id)
    }

    render() {
        return (
            <Container maxWidth={false} sx={{ minHeight: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', backgroundColor: deepPurple[100] }} id="skills">
                <Grid container spacing={2} sx={{my: 2}}>
                {/* <Grid item xs={12} sx={{ mb: 6, textAlign: 'center' }}><Typography variant="h4"><b>Easy intelligent investing</b></Typography></Grid> */}
                
                    <Grid item xs={12} lg={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'center', py: { xs: 8, md: 1 }, px: { xs: 4, xl: 24 } }}>
                            {/* <MemoryTwoTone color="primary" sx={{ fontSize: 80 }} /> */}
                            <img src="img/skills-smart.png" />
                            <Typography variant="h2" sx={{ my: 2, display: {xs: 'none', md: 'block'} }}><b>Smart</b></Typography>
                            <Typography variant="h3" sx={{ my: 2, display: {xs: 'block', md: 'none'} }}><b>Smart</b></Typography>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" sx={{ fontWeight: 300 }}>
                                    Braino combines multiple
                                    analytical and decision-making
                                    methodologies, with the power of
                                    the latest Artificial Intelligence
                                    technologies.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6} sx={{ backgroundColor: deepPurple[150] }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'center', py: { xs: 8, md: 1 }, px: { xs: 4, xl: 24 } }}>
                            {/* <ThumbUpTwoTone color="primary" sx={{ fontSize: 80 }} /> */}
                            <img src="img/skills-simple.png" />
                            <Typography variant="h2" sx={{ my: 2, display: {xs: 'none', md: 'block'} }}><b>Simple</b></Typography>
                            <Typography variant="h3" sx={{ my: 2, display: {xs: 'block', md: 'none'} }}><b>Simple</b></Typography>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" sx={{ fontWeight: 300 }}>Braino makes easy what is not so
                                    easy.
                                    It analyses the markets, identifies
                                    the best opportunities, and
                                    manages each investment
                                    position.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <IconButton sx={{ my: 5 }} onClick={() => this.scrollTo('skills2')}>
                            <KeyboardArrowDown color="primary" sx={{ fontSize: 80 }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default Skills1;