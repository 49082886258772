import { createTheme } from "@mui/material";

const ThemeLight = createTheme({
  green: '#1eb980',
  red: '#ff6859',
  empty: {
    background: '#c2c2c2',
    width: '100%',
    height: '100px'
  },
  cardSettings: {
    background: '#3d5afe11'
  },
  paper: { background: 'trasparent', boxShadow: 'none', padding: '16px' },
  palette: {
    typography: {
      fontFamily: [
        'Barlow',
        'sans-serif'
      ].join(','),
    },
    emptyChart: {
      background: '#e6e6e6',
      width: '100%',
      height: '100px'
    },
    accent: '#FEFEFE',
    accent2: '#FA18F8',
    chartPrimary: '#3A75FD',
    chartPrimaryLight: '#3A75FD',
    chartSecondary: '#FFB623', //salmone ff8860
    chartSecondaryLight: '#FFB623', //salmone ff8860
    chartThird: '#B77EA3', //violetto B77EA3
    grid: '#f2f2f2',
    background: {
      primary: '#fff',
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#484848',
      secondary: '#a0a0a0',
      red: '#f00',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
    textSecondary: {
      main: '#848484',
    },
    success: {
      main: '#15BAC0',
      //contrastText: "#FFFFFF"
    },
    cta: {
      main: '#ff8a5e',
      light: '#ff956e',
      dark: '#e57c54',
    },
    accent2: {
      main: '#FA18F8',
    },
    primary: {
      main: '#8D21E9',
      // dark: '#da39f2',
      /*light: '#fafafa',
      dark: '#fff',
      contrastText: "#fff"*/
    },
    secondary: {
      main: '#3A75FD',
      // dark: will be calculated from palette.secondary.main,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.1,
  },
  components: {
    common: {
      black: '#848484'
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: "#f0f0f0",
          },
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #f0f0f0!important'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#F5F6F8!important',
        }
      }
    },
    MuiButton: {
      variants: [
        {
          props: { size: "extraLarge" },
          style: { padding: '12px 24px', fontSize: '1.2rem', }
        }
      ],
    styleOverrides: {
        root: {
          fontFamily: 'Barlow, sans-serif',
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: 'inherit'
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#f0f0f0',
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fafafa!important',
          color: '#484848',
          boxShadow: 'none',
          borderRadius: '0!important',
          border: "0px solid #8d21e9!important",
          borderColor: "#8d21e9!important",
          margin: 0,
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          borderRadius: 8,
          height: 'auto',
          maxHeight: '100%',
          margin: '8px',
          boxShadow: '0px 0px 19px 0px rgb(0 0 0 / 10%)',
        },
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingBottom: 8,
        },
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          fontWeight: 300,
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          border: "1px solid inherit!important",
        }
      }
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: '#ccc!important',
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 4
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          // backgroundColor: '#fff!important',
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 'inherit',
          fontFamily: 'Barlow, sans-serif',
          fontWeight: 500
        },
        h1: {
          fontSize: '5rem',
        },
        body1: {
          fontWeight: 400,
        },
        overline: {
          letterSpacing: '.3em !important',
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            backgroundColor: "transparent!important",
          },
          '&:after': {
            position: "absolute",
            left: 0,
            top: -1,
            right: 0,
            height: 1,
            content: '""',
            opacity: 1,
            backgroundColor: "#e6e6e6!important",
          },
        },
      }
    },
  }
});

export default ThemeLight;