import { ChevronLeft } from "@mui/icons-material";
import { Box, Button, Checkbox, Container, Divider, Fade, Grid, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Component } from "react";
import { useNavigate, useParams } from "react-router";
import { NavLink } from "react-router-dom";

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class PostBase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            id: 0,
        }
    }

    componentDidMount() {
        let { id } = this.props.params;
        this.setState({ id })
        axios.get('https://insights.braino.ai/wp-json/wp/v2/posts?_embed&include=' + id + '&lang=en')
            .then(response => {
                const data = response.data[0]
                this.setState({ data })
            })
            .catch(error => {
                console.log(error);
            });

    }

    back() {
        this.props.navigate(-1)
    }

    render() {
        const { data, id } = this.state
        return (
            <Fade in="true" timeout={650} >
                <Container maxWidth='lg'>
                    <Grid container item spacing={2} sx={{px: 4}}>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Button startIcon={<ChevronLeft />} onClick={() => this.back()}>Back</Button>
                        </Grid>
                        <Grid item xs={12} display='flex' flexDirection={'column'} sx={{ mb: 2, textAlign: 'left' }}>
                            {data ?
                                <>
                                    <img style={{ maxWidth: '100%' }} src={data._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url} />
                                    <Typography sx={{ mt: 3 }} variant="h4"><b dangerouslySetInnerHTML={{ __html: data ? data.title.rendered : "-" }} /></Typography>
                                    <Typography variant="caption" color={'textSecondary'}><div dangerouslySetInnerHTML={{ __html: data ? data.excerpt.rendered : "-" }} /></Typography>
                                </>
                                : ""
                            }
                        </Grid>
                        <Grid item xs={12} display='flex' flexDirection={'column'} sx={{ textAlign: 'left' }}>
                            {data ?
                                <Typography variant="body1"><div dangerouslySetInnerHTML={{ __html: data.content.rendered }} /></Typography>
                                : "-"}
                        </Grid>
                    </Grid>
                </Container>
            </Fade>
        )
    }
}

export function Post(props) {
    const navigate = useNavigate()

    return <PostBase navigate={navigate} {...props} />
}

export default withParams(Post)
