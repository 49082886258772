import { Box, Container, Grid } from "@mui/material";
import { Component } from "react";
import Footer from "../components/Footer";

class FooterSection extends Component {
    render() {
        return (
            <Container maxWidth={false} id="footer" sx={{ p: '0!important', minHeight: '100vh', backgroundColor: '#2f2f37', display: 'flex' }}>
                <Footer />
            </Container>
        );
    }
}

export default FooterSection;