import { Box, Card, Container, Grid, IconButton, Typography } from "@mui/material";
import { Component } from "react";
import { DataArray, FilterAltTwoTone, Hub, HubTwoTone, KeyboardArrowDown, Numbers, Rocket, RocketLaunch, RocketLaunchTwoTone, SettingsSuggest, StackedBarChart, StackedBarChartTwoTone, Storage, StorageTwoTone, TipsAndUpdates, TipsAndUpdatesTwoTone } from "@mui/icons-material";
import { grey, yellow } from "@mui/material/colors";
import CountUp from 'react-countup';

class HowItWorks2 extends Component {
    render() {
        return (
            <Container maxWidth={false} sx={{
                minHeight: { xs: '00vh', lg: '50vh' }, display: 'flex', alignItems: 'center',
                py: 6,
                backgroundColor: '#fff',
            }} id="howitworks">
                <Grid container sx={{ px: { sx: 4, lg: 8, xl: 8 } }} spacing={2}>
                    <Grid item xs={12} sx={{ mb: 6, textAlign: 'left' }}>
                        <Box sx={{px: { xs: 1, lg: 8, xl: 8 }}}>
                        <Typography variant="h4"><b>Reach your goals, step-by-step</b></Typography>
                        <Typography variant="body1">Braino is your best friend to invest smarter, effortless.</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <Box sx={{ height: '100%', py: { xs: 3, md: 1 }, px: { xs: 1, lg: 8, xl: 8 }, display: 'flex', flexDirection: 'row', position: 'relative' }}>
                            <Box sx={{ textAlign: 'center', mb: 3, mr: 2, pt: 1, display: { xs: 'none', md: 'block' } }}>
                                <img src="img/hiw-profile.png" style={{ height: 100 }} />
                            </Box>
                            <Box sx={{ textAlign: 'center', mb: 3, mr: 2, pt: 1, display: { xs: 'block', md: 'none' } }}>
                                <img src="img/hiw-profile.png" style={{ height: 70 }} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h2" sx={{ display: { xs: 'none', md: 'block' } }}><b>Profile</b></Typography>
                                <Typography variant="h3" sx={{ display: { xs: 'block', md: 'none' } }}><b>Profile</b></Typography>
                                <Typography variant="h6" sx={{ minHeight: 150, fontWeight: 300 }}>Let Braino know your investment style.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Box sx={{ height: '100%', py: { xs: 3, md: 1 }, px: { xs: 1, lg: 8, xl: 8 }, display: 'flex', flexDirection: 'row', position: 'relative' }}>
                            <Box sx={{ textAlign: 'center', mb: 3, mr: 2, pt: 1, display: { xs: 'none', md: 'block' } }}>
                                <img src="img/hiw-invest.png" style={{ height: 100 }} />
                            </Box>
                            <Box sx={{ textAlign: 'center', mb: 3, mr: 2, pt: 1, display: { xs: 'block', md: 'none' } }}>
                                <img src="img/hiw-invest.png" style={{ height: 70 }} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h2" sx={{ display: { xs: 'none', md: 'block' } }}><b>Invest</b></Typography>
                                <Typography variant="h3" sx={{ display: { xs: 'block', md: 'none' } }}><b>Invest</b></Typography>
                                <Typography variant="h6" sx={{ minHeight: 150, fontWeight: 300 }}>Follow Braino and invest in the most interesting securities and portfolios.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Box sx={{ height: '100%', py: { xs: 3, md: 1 }, px: { xs: 1, lg: 8, xl: 8 }, display: 'flex', flexDirection: 'colrowumn', position: 'relative' }}>
                            <Box sx={{ textAlign: 'center', mb: 3, mr: 2, pt: 1, display: { xs: 'none', md: 'block' } }}>
                                <img src="img/hiw-relax.png" style={{ height: 100 }} />
                            </Box>
                            <Box sx={{ textAlign: 'center', mb: 3, mr: 2, pt: 1, display: { xs: 'block', md: 'none' } }}>
                                <img src="img/hiw-relax.png" style={{ height: 70 }} />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="h2" sx={{ display: { xs: 'none', md: 'block' } }}><b>Relax</b></Typography>
                                <Typography variant="h3" sx={{ display: { xs: 'block', md: 'none' } }}><b>Relax</b></Typography>
                                <Typography variant="h6" sx={{ minHeight: 150, fontWeight: 300 }}>Braino manages your investments and alerts you when it is time to trade.</Typography>
                            </Box>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default HowItWorks2;