import { Box, Button, Container, Grid, IconButton, Typography } from "@mui/material";
import { Component } from "react";
import { NavLink } from "react-router-dom";
import { BatteryChargingFullTwoTone, EscalatorTwoTone, FaceTwoTone, KeyboardArrowDown, MemoryTwoTone, ThumbUpTwoTone, ViewModuleTwoTone } from "@mui/icons-material";
import { deepPurple } from "@mui/material/colors";

class Skills2 extends Component {
    scrollTo(id) {
        this.props.scrollTo(id)
    }

    render() {
        return (
            <Container maxWidth={false} sx={{ minHeight: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', backgroundColor: deepPurple[200] }} id="skills2">
                <Grid container item md={12} order={{}} spacing={2} sx={{my: 2}}>
                    <Grid item lg={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'center', py: { xs: 8, md: 1 }, px: { xs: 4, xl: 24 } }}>
                            {/* <FaceTwoTone color="primary" sx={{ fontSize: 80 }} /> */}
                            <img src="img/skills-personal.png" />
                            <Typography variant="h2" sx={{ my: 2, display: {xs: 'none', md: 'block'} }}><b>Personal</b></Typography>
                            <Typography variant="h3" sx={{ my: 2, display: {xs: 'block', md: 'none'} }}><b>Personal</b></Typography>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" sx={{ fontWeight: 300 }}>There&#39;s a MyBraino for every
                                    user, and it gets to know you by
                                    the choices you make every day.
                                    You can follow everything Braino
                                    suggests for you, or choose
                                    which investment operations to
                                    follow, and which securities you
                                    prefer.</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', textAlign: 'center', py: { xs: 8, md: 1 }, px: { xs: 4, xl: 24 } }}>
                            {/* <ViewModuleTwoTone color="primary" sx={{ fontSize: 80 }} /> */}
                            <img src="img/skills-flexible.png" />
                            <Typography variant="h2" sx={{ my: 2, display: {xs: 'none', md: 'block'} }}><b>Flexible</b></Typography>
                            <Typography variant="h3" sx={{ my: 2, display: {xs: 'block', md: 'none'} }}><b>Flexible</b></Typography>
                            <Box sx={{ textAlign: 'left' }}>
                                <Typography variant="h6" sx={{ fontWeight: 300 }}>Braino can suit every need,
                                    investment profile and capital
                                    size, from micro-investments to
                                    large asset management.</Typography>
                            </Box>
                        </Box>
                       
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <IconButton sx={{ my: 5 }} onClick={() => this.scrollTo('skills3')}>
                            <KeyboardArrowDown color="primary" sx={{ fontSize: 80 }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default Skills2;