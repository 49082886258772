import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Button, Card, CardActionArea, CardContent, CardMedia, Checkbox, Container, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import axios from "axios";
import { Component } from "react";
import { NavLink } from "react-router-dom";

class Posts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }

    componentDidMount() {
        axios.get('https://insights.braino.ai/wp-json/wp/v2/posts?_embed&page=1&per_page=5&lang=en')
            .then(response => {
                const data = response.data
                this.setState({ data })
            })
            .catch(error => {
                console.log(error);
            });

    }

    render() {
        const { data } = this.state
        return (
            <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', px: {xs: 0, md: 7} }}>
                        <Typography variant="body2"><b>BLOG</b></Typography>
                        <Typography sx={{ mt: 1 }} variant="h4"><b>Discover more about Braino.</b></Typography>
                        <Typography>Articles, performance updates and reports to understand better your Investment coach, progress and achievements.</Typography>
                        <Box sx={{ mt: 4 }}><Button variant="outlined" component={NavLink} to='/blog'>Take a look!</Button></Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: {xs: 'block', md: 'none'}, my: 4 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12} md={8}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', px: {xs: 0, md: 4} }}>
                        {data ? data.map((obj, i) => (
                            obj.polylang_current_lang.startsWith("en") &&
                            <> 
                                {i > 0 ? <Divider sx={{ my: 3, borderColor: grey[200] }} /> : ""}
                                <Box sx={{ display: 'flex', flexDirection: {xs: 'column', md: 'row'}, alignItems: {xs: 'start', md: 'center'} }}>
                                    <Box sx={{mr: 2}}>
                                        <img style={{ maxWidth: '100%', borderRadius: 4 }} src={obj._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url} />
                                    </Box>
                                    <Box sx={{ py: 2, px: 0 }}>
                                        <Typography variant="h6"><b dangerouslySetInnerHTML={{ __html: obj.title.rendered.length > 120 ? obj.title.rendered.substring(0, 120) + '...' : obj.title.rendered }} /></Typography>
                                        <Typography variant="body1"><div dangerouslySetInnerHTML={{ __html: obj.excerpt.rendered.length > 120 ? obj.excerpt.rendered.substring(0, 120) + '...' : obj.excerpt.rendered }} /></Typography>
                                        <Button size="small" component={NavLink} to={'/post/' + obj.id} endIcon={<ArrowRightAlt />}>Read more</Button>
                                    </Box>
                                </Box>
                            </>
                        )) : "loading..."
                        }
                    </Box>
                </Grid>
            </Grid>
        )
    }
}

export default Posts

