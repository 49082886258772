import { Component } from "react";
import ReactGa from 'react-ga';
import { Fullpage, Slide } from 'fullpage-react';
import { BrowserView, isMobile, MobileOnlyView } from "react-device-detect";
import Banner from "../sections/Banner";
import Skills from "../sections/Skills1";
import Performance from "../sections/Performance";
import Plans from "../sections/Plans";
import Braino from "../sections/Braino";
import Partners from "../sections/Partners";
import Blog from "../sections/Blog";
import Section1 from "../sections/Section1";
import Section2 from "../sections/Section2";
import Section3 from "../sections/Section3";
import FooterSection from "../sections/FooterSection";
import Skills1 from "../sections/Skills1";
import Skills2 from "../sections/Skills2";
import Skills3 from "../sections/Skills3";
import { Box, Container } from "@mui/material";
import HowItWorks from "../sections/HowItWorks";
import HowItWorks2 from "../sections/HowItWorks2";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: {
                Fullpage: 0
            },
            width: window.innerWidth,
            anchor: 0,
        }
    }

    componentDidMount() {
        ReactGa.pageview("/" + window.location.hash.replace("#/", ""));

        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth });
    };

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            if (this.props.anchor != prevProps.anchor) {
                const active = { Fullpage: 2 };
                this.setState({ active });
            }
        }
    }

    onSlideChangeStart(name, props, state, newState) {
    }

    onSlideChangeEnd = (name, props, state, newState) => {
        const oldActive = this.state.active;
        const active = { Fullpage: newState.activeSlide };

        this.setState({ active });
    }

    scrollTo = (id) => {
        window.scrollTo({
            behavior: 'smooth',
            top:
                document.getElementById(id).getBoundingClientRect().top -
                document.body.getBoundingClientRect().top - 80,
        })
    }

    render() {
        const { width, active, anchor } = this.state;

        const fullPageOptions = {
            scrollSensitivity: 0,
            touchSensitivity: 1,
            scrollSpeed: 200,
            hideScrollBars: true,
            enableArrowKeys: true
        }
        const slides = [
            <Slide><Banner /></Slide>,
            <Slide><Skills1 scrollTo={this.scrollTo} /></Slide>,
            <Slide><Skills2 /></Slide>,
            <Slide><Skills3 /></Slide>,
            <Slide><Section1 /></Slide>,
            <Slide><Section2 /></Slide>,
            <Slide><Section3 /></Slide>,
            <Slide><Performance /></Slide>,
            <Slide><Plans /></Slide>,
            <Slide><Braino /></Slide>,
            <Slide><Partners /></Slide>,
            // <Slide><Blog /></Slide>,
            <Slide><FooterSection /></Slide>,
        ]
        fullPageOptions.slides = slides;

        return (
            <Box>
                <Banner />
                {/* <Section1 scrollTo={this.scrollTo} />
                <Section2 scrollTo={this.scrollTo} />
                <Section3 /> */}
                {/* <HowItWorks /> */}
                <HowItWorks2 />
                <Skills1 scrollTo={this.scrollTo} />
                <Skills2 scrollTo={this.scrollTo} />
                <Skills3 />
                <Performance />
                <Plans />
                <Braino />
                <Partners />
                {/* <Blog /> */}
                <FooterSection />
            </Box>
        )
    }
}

export default Home

