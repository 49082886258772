import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import { Component } from "react";

class Section3 extends Component {
    render() {
        return (
            <Container maxWidth={false} id="other3" sx={{ p: '0!important', minHeight: {xs: 'calc(100vh - 80px)', md:'calc(100vh - 80px)'}, display: 'flex' }}>
                <Grid container sx={{ minHeight: '100%' }}>
                    <Grid item xs={12} md={6} order={{xs: 2, md: 1}}>
                        <Box sx={{ height: '100%', py: { xs: 8, md: 1 }, px: { xs: 4, lg: 16, xl: 32 }, display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>
                            <Typography variant="h1" color={'primary'}><b>Relax</b></Typography>
                            <Typography variant="h4" sx={{ fontWeight: 400 }}>Braino manages your investments and alerts you when it is time to trade.</Typography>
                            <Box>
                            <Button variant='contained' size='extraLarge' disableElevation sx={{ mt: 5 }} component={Link} href='//app.braino.ai' target={'APP'}>Try now</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} order={{xs: 1, md: 2}} sx={{ minHeight: 200, backgroundImage: 'url(https://images.unsplash.com/photo-1517325619117-a469256220f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2080&q=80)', backgroundSize: 'cover' }}>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default Section3;